<template>
  <div class="resumeForm">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <!---------------------->
      <b-form-group
        id="input-group-firstname"
        label="Your First Name:"
        label-for="input-firstname"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="person-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="input-firstname"
            v-model="contactInfo.firstname"
            placeholder="First name"
            required
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-last"
        label="Your Last Name:"
        label-for="input-lastname"
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="person-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="input-lastname"
            v-model="contactInfo.lastname"
            placeholder="Last name"
            required
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-1"
        label="Email address:"
        label-for="input-1"
        type="email"
        description="This will apear on your resume."
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="envelope-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="input-1"
            v-model="contactInfo.email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-phone"
        label="Phone"
        label-for="input-1"
        type="phone"
        description="This will apear on your resume."
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="telephone-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="input-1"
            v-model="contactInfo.phone"
            placeholder="Phone#"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-website"
        label="website"
        label-for="input-7"
        description="This will apear on your resume."
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="link"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="input-7"
            v-model="contactInfo.website"
            placeholder="website"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <!---------------------->
      <b-form-group
        id="input-group-address"
        label="Address on resume"
        label-for="input-address"
        description="This will apear on your resume."
      >
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="geo-alt-fill"></b-icon>
          </b-input-group-prepend>
          <b-form-input
            id="input-address"
            v-model="contactInfo.address"
            placeholder="i.e: City, State"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
      <!---------------------->
      <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.checked"
          id="checkboxes-4"
          :aria-describedby="ariaDescribedby"
        >
          <b-form-checkbox value="save_profile"
            >Save to my profile for the future resumes</b-form-checkbox
          >
        </b-form-checkbox-group>
      </b-form-group>
      <!---------------------->
      <hr />
      <b-button
        v-on:click="submit_form(false, $event)"
        id="bt_save"
        variant="outline-primary"
        >Save</b-button
      >

      <b-button
        v-on:click="submit_form(true, $event)"
        id="bt_submit"
        variant="success"
        class="ml-5"
        >Next: Experinces &#10132;</b-button
      >

      <!-- <b-form-group id="input-group-reset">
        <b-button type="reset" variant="danger" class="mr-2 mt-2"
          >Reset</b-button
        >
      </b-form-group> -->
    </b-form>
  </div>
</template>
<script>
export default {
  name: "ContactInfoForm",
  data() {
    return {
      form: {
        checked: [],
      },
      show: true,
    };
  },
  props: ["contactInfo"],
  methods: {
    submit_form: function (redirect, event) {
      event.preventDefault();
      console.log("form submitted ");
      this.$emit("updateContactInfo", this.contactInfo, redirect);
    },
    onSubmit(event) {
      //console.log("submit-type:" + this.form.submit.value);
      event.preventDefault();
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values

      this.contactInfo.firstname = "";
      this.contactInfo.lastname = "";
      this.contactInfo.phone = "";
      this.contactInfo.email = "";
      this.contactInfo.website = "";
      this.contactInfo.address = "";
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style lang=""></style>
