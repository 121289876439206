<template lang="">
  <div class="text-center">
    <h3>Resume Contact Information</h3>
    <contact-info-form
      class="text-left"
      :contactInfo="$store.state.resume.contactInfo"
      @updateContactInfo="updateContactInfo"
    />
  </div>
</template>
<script>
import ContactInfoForm from "../components/forms/FormContactInfo.vue";
export default {
  data() {
    return {
      contactInfo: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        address: "",
      },
    };
  },
  components: {
    "contact-info-form": ContactInfoForm,
  },
  created() {
    //this.$api.fetchResumeContacts();
  },
  methods: {
    updateContactInfo(contactInfo, redirect) {
      let resume = this.$store.state.resume;
      this.$store.state.resume.contactInfo = contactInfo;
      resume.contactInfo = contactInfo;
      this.$store.commit("updateResume", resume);
      this.$api.updateResumeContacts();
      this.$api.successToast();
      if (redirect) this.$router.push("experinces");
    },
  },
};
</script>
<style lang=""></style>
